import { makeStyles } from 'tss-react/mui'

import CssVariables from '@core/assets/styles/css-variables'

export default makeStyles()(() => ({
  validationIcon: {
    position: 'absolute',
    color: CssVariables.green,
    cursor: 'auto',
    right: -30,
  },
  regexRules: {
    width: '100%',
    padding: 4,
    fontSize: 10,
    color: '#000000DE',
    marginBottom: 16,
    '& .RegexSuccess': {
      color: '#00000061'
    }
  },
  regexRulesIcon: {
    marginRight: '4px',
    fontSize: '12px'
  }
}))
