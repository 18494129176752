import { AppAccess } from '@extensiv/sdk'
import { IExtensivSnackbarProps, SnackbarSeverity, useSnackbar } from '@extensiv/shared-reactcomponents'
import * as globalUtility from '@extensiv-app/utility'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import axios from 'axios'
import { pickBy, startsWith } from 'lodash'
import { useLocation } from 'react-router-dom'

import { getUserInfo } from '@core/redux/authSlice'
import { setCurrentOrganization } from '@core/redux/authSlice'
import { STAGE } from '@core/services/config'

declare let pendo: any
let pendoIsInitialized = false

export const useQueryParams: any = () => new URLSearchParams(useLocation().search)

export const emailMask = (mail: string, maskFromBeginning = 2) => {
  const str = mail.split('')
  const finalArr: any = []
  const len = str.indexOf('@') - 1
  str.forEach((item, index: number) => {
    (index >= maskFromBeginning && index <= len)
      ? finalArr.push('•')
      : finalArr.push(str[index])
  })
  return finalArr.join('')
}

export const useSnackBar = () => {
  const { enqueueSnackbar } = useSnackbar()
  const defaultOption: Partial<IExtensivSnackbarProps> = {
    autoHideDuration: 3000,
    preventDuplicate: true,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    style: { whiteSpace: 'pre-line' },
    variant: 'extensivSnackbar',
    icon: true,
    showTitle: false,
    title: 'Default Title',
    showAction: false,
    actionLabel: 'Default Label',
    closeable: true,
  }

  type OptionType = Partial<IExtensivSnackbarProps> | null

  return {
    showSuccessMessageAlert: (message: string | React.ReactNode, option?: OptionType) => {
      defaultOption.severity = SnackbarSeverity.Success
      const newOptions = Object.assign({}, defaultOption, option)
      enqueueSnackbar(message, newOptions)
    },
    showWarningMessageAlert: (message: string | React.ReactNode, option?: OptionType) => {
      defaultOption.severity = SnackbarSeverity.Warning
      const newOptions = Object.assign({}, defaultOption, option)
      enqueueSnackbar(message, newOptions)
    },
    showErrorMessageAlert: (message: string | React.ReactNode, option?: OptionType) => {
      defaultOption.severity = SnackbarSeverity.Error
      const newOptions = Object.assign({}, defaultOption, option)
      enqueueSnackbar(message, newOptions)
      // playSound('error')
    },
    showInformationMessageAlert: (message: string | React.ReactNode, option?: OptionType) => {
      defaultOption.severity = SnackbarSeverity.Info
      const newOptions = Object.assign({}, defaultOption, option)
      enqueueSnackbar(message, newOptions)
      // playSound('info')
    },
    enqueueSnackbar: (message: string, option?: OptionType) => {
      defaultOption.severity = SnackbarSeverity.Success
      const newOptions = Object.assign({}, defaultOption, option)
      enqueueSnackbar(message, newOptions)
    }
  }
}

export const onChangeAuth = async (dispatch: any, response: any) => {
  axios.defaults.headers.common['Authorization'] = ''

  if (response?.idToken?.jwtToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${response.idToken.jwtToken}`
  }



  if (response?.idToken?.payload) {
    const orgKey: any = response?.idToken?.payload['custom:org']
    const extensivId: any = response?.idToken?.payload['extensivId']
    const userId = response?.idToken?.payload['cognito:username']
    const { type, payload } = await dispatch(getUserInfo(userId))

    if (type?.includes('fulfilled') && payload?.authUser) {
      await setProductAccessFromUserOrOrg(dispatch, payload?.authUser, orgKey)
      initializePendo(payload?.authUser, orgKey, extensivId)
    }

    dispatch(setCurrentOrganization({ orgKey }))
  } else {
    dispatch(setCurrentOrganization({ orgKey: null }))
  }
}

export const setProductAccessFromUserOrOrg = async (dispatch: any, userInfo: any, orgKey: string) => {
  const orgObj = userInfo?.orgs.find((obj: any) => obj.key === orgKey)

  if (!orgObj) {
    console.log('no org obj found')
    return
  }

  // setting context org apps user wise or for super user organization wise
  // let contextOrgApps = null
  // if (userInfo?.contextOrgApps) {
  //     contextOrgApps = { ...userInfo?.contextOrgApps }
  // }
  const appPermissionForOrg = pickBy(userInfo?.contextOrgApps, function(value, key) {
    return startsWith(key, 'can')
  })

  globalUtility.globalActiveProducts.next(appPermissionForOrg)
}



// TODO: need to uncomment and find solution for get all permissions
// export const getAllPermission = () => {
//     const lengthPrototypeAndName = Object.getOwnPropertyNames(corePermissions)
//     const permissionList = lengthPrototypeAndName
//         .filter((obj) => !['name', 'length', 'prototype'].includes(obj))
//         .map((vars) => ({ text: (vars).split('_').join(' '), value: ((corePermissions as any)[vars]) }))
//     // Please keep it, To getAllPermissionByGroup the group by data this will help
//     // .map((vars) => ({ title: (vars).split('_').join(' '), value: corePermissions.${vars}`, description: 'description', subPermissions: [] })
//     return permissionList
// }

// TODO: need to uncomment and find solution
// export const getAllPermissionByGroup = () => {
//     // console.log(getAllPermission())
//     return groupedByPermissionsList
// }

// TODO: need to uncomment and find solution
// export const getPermissionByValue = (value: string) => {
//     const allPermissionByGroup = getAllPermission()
//     return find(allPermissionByGroup, { value })
// }

export const initializePendo = (userInfo: any, orgKey: string, extensivId: string) => {
  const usersAppAccess = listOrgAccess(userInfo.contextOrgApps)
  const orgName = findOrgName(userInfo.orgs, orgKey)
  if ((STAGE === 'STAGING' || STAGE === 'PROD' || STAGE === 'DEVS' || STAGE === 'SANDBOX') && (typeof pendo !== 'undefined')) {
    const PendoObject = {
      visitor: {
        id: userInfo.email || '',
        hub_id: userInfo.id || '',
        email: userInfo.email || '',
        full_name: `${userInfo.firstName} ${userInfo.lastName}` || '',
        environment: STAGE || '',
        isSuperUser: userInfo.isSuperUser || '',
        ...usersAppAccess
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      account: {
        id: extensivId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        orgKey,
        name: orgName
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    }
    if (pendoIsInitialized) {
      pendo.identify(PendoObject)
    } else {
      pendo.initialize(PendoObject)
      pendoIsInitialized = true
    }
  }
}

export const appKeyToPackageMap: { [key: string]: string } = {
  'wms-smartui': '@extensiv-app/3pl-warehouse-manager-wrapper',
  'wms-management-console': '@extensiv-app/management-console-wrapper',
  'wms-signup': '@extensiv-app/signup-wrapper',
  'billing': '@extensiv-app/billing-master',
  '4pl-network': '@extensiv-app/network-manager',
  'hub': '@extensiv-app/hub',
  'skubana-legacy': '@extensiv-app/order-manager-wrapper',
  'top-shelf-classic-admin': '@extensiv-app/warehouse-manager-classic-admin-wrapper',
  'top-shelf-unlimited-admin': '@extensiv-app/warehouse-manager-unlimited-admin-wrapper',
  'top-shelf-customer-portal': '@extensiv-app/warehouse-manager-customer-portal-wrapper',
  'cart-rover': '@extensiv-app/integration-manager-wrapper'
}

export const appNameToHumanReadable: { [key: string]: string } = {
  '@extensiv-app/hub': 'Extensiv',
  '@extensiv-app/global': 'Extensiv Global',
  '@extensiv-app/core': 'Extensiv Core',
  '@extensiv-app/network-manager': 'Network Manager',
  '@extensiv-app/billing-master': 'Billing Manager',
  '@extensiv-app/integration-manager-wrapper': 'Integration Manager',
  '@extensiv-app/3pl-warehouse-manager-wrapper': '3PL Warehouse Manager',
  '@extensiv-app/management-console-wrapper': 'Management Console',
  '@extensiv-app/signup-wrapper': 'Sign Up',
  '@extensiv-app/order-manager-wrapper': 'Order Manager',
  '@extensiv-app/warehouse-manager-classic-admin-wrapper': 'Warehouse Manager',
  '@extensiv-app/warehouse-manager-unlimited-admin-wrapper': 'Warehouse Manager Unlimited Admin',
  '@extensiv-app/warehouse-manager-customer-portal-wrapper': 'Warehouse Manager Customer Portal'
}

const findOrgName = (orgList: any[], orgKey: string) => {
  const contextOrg: any = orgList.find(org => org.key === orgKey)
  return contextOrg ? contextOrg.displayName : ''
}

const listOrgAccess = (userAccess: any) => {
  const appAccessList: any = {}
  const appAccessNames: string[] = AppAccess.allAppAccessNames
  appAccessNames.forEach(app => {
    let enabled = false
    for (const product in userAccess) {
      if (app == product) {
        enabled = !!userAccess[product]
      }
    }
    appAccessList[app] = enabled
  })
  return appAccessList
}
