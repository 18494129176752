import { makeStyles } from 'tss-react/mui'

import CssVariables from '@core/assets/styles/css-variables'

export const useStyles = makeStyles()(() => ({
  PageContainer: {
    height: '100%',
    backgroundColor: CssVariables.white,
    alignContent: 'baseline'
  },
  ExtensivLogo: {
    marginLeft: '24px',
    marginTop: '16px'
  },
  MainFormBody: {
    justifyContent: 'center',
    marginTop: '56px'
  },
  ResetFormContainer: {
    maxWidth: '360px'
  },
  LoginLink: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  TextInput: {
    height: '56px'
  },
  VerificationCodeContainer: {
    background: '#F5F5F5',
    borderRadius: '4px',
    padding: '24px'
  }
}))
