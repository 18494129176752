import { useEffect } from 'react'

import { websocketMessageListener } from '@core/websockets/WebsocketMessageListener'
import { websocketMessageRouter } from '@core/websockets/WebsocketMessageRouter'

export default (selectedOrgId: string, userId: string) => {
  useEffect(() => {
    if (!selectedOrgId || !userId) return

    const userTopic = `${selectedOrgId}#${userId}`
    websocketMessageListener.subscribeToTopic(userTopic)
    websocketMessageRouter.setUserTopicRoute(userTopic)

    return () => {
      websocketMessageRouter.removeUserTopicRoute(userTopic)
      websocketMessageListener.unsubscribeFromTopic(userTopic)
    }
  }, [ selectedOrgId, userId ])
}
