import { makeStyles } from 'tss-react/mui'

import CssVariables from './css-variables'

// All common css classes like form button will be defined here centrally
export default makeStyles()(() => ({
  dFlexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textFieldCustom: {
    marginBottom: 14,
    '& .MuiFormLabel-root': {
      fontSize: 10,
      marginBottom: 6,
      color: CssVariables.labelLight,
      position: 'unset',
      transform: 'none',
    },
    '& .MuiInputBase-root input': {
      padding: '10.5px 14px',
      maxHeight: 40
    },
    '& .MuiInputBase-root fieldset': {
      top: 0
    },
    '& .MuiInputBase-root fieldset legend': {
      display: 'none',
    },
  },
  spinnerOverride: {
    position: 'absolute'
  }
}))
