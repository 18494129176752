import * as globalUtility from '@extensiv-app/utility'
import { w3cwebsocket as WebSocketClient } from 'websocket'

import { WebsocketTopic } from '@core/websockets/WebsocketTopic'

class WebsocketMessageListener {
  ws
  wsIsOpen: Promise<void>
  updatedApps: string[] = []

  constructor() {
    this.ws = new WebSocketClient(globalUtility.environments.WEBSOCKET_URL || '')

    this.wsIsOpen = new Promise(resolve => {
      this.ws.onopen = () => {
        this.fancyLog('Websocket connection established')
        resolve()
      }
    })

    this.subscribeToTopic(WebsocketTopic.APPLICATION_VERSION_UPDATE)

    this.ws.onclose = ({ code }) => {
      if (code === 1001) {
        this.fancyLog('Connection closed! Re-establishing connection.')

        this.ws = new WebSocketClient(globalUtility.environments.WEBSOCKET_URL || '')
      }
    }

    this.ws.onmessage = e => {
      if (typeof e.data !== 'string' || e.data === 'pong') return

      globalUtility.websocketMessages.next(JSON.parse(e.data))
    }

    this.pingLoop()
  }

  fancyLog = (content: string) => {
    const party = String.fromCodePoint(0x1f389)
    const logStyle = 'background: #00008b; color: #ADD8E6; padding: 5px 10px;'

    console.log(`%c ${party} ${content}`, logStyle)
  }

  // AWS terminates websocket connections after 10 minutes of inactivity. We set
  // up a ping loop to send at least one ping message every 9.8 minutes
  pingLoop = () => {
    setTimeout(() => {
      if (this.ws.readyState === this.ws.OPEN) {
        this.ws.send(JSON.stringify({ action: 'ping' }))
      }
      this.pingLoop()
    }, 9.8 * 60 * 1000)
  }

  subscribeToTopic = async (topic: WebsocketTopic | string) => {
    await this.wsIsOpen
    this.ws.send(JSON.stringify({ action: 'subscribeToTopic', topic }))
  }

  unsubscribeFromTopic = async (topic: WebsocketTopic | string) => {
    await this.wsIsOpen
    this.ws.send(JSON.stringify({ action: 'unsubscribeFromTopic', topic }))
  }
}

export const websocketMessageListener = new WebsocketMessageListener()
