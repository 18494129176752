import { useMemo, useState, useRef } from 'react'

import { CoreLink, Text, CoreTypography, AppBadge } from '@extensiv/shared-reactcomponents'
import { Popover, MenuList, MenuItem } from '@mui/material'
import moment from 'moment'

import { useAppSelector } from '@core/redux/store'
import { appKeyToPackageMap, appNameToHumanReadable } from '@core/util/commonFunctions'

import useStyles from './styles'

interface IProps {
  availableProducts: any[]
  onAppSelect(): any
}


export default ({ availableProducts, onAppSelect }: IProps) => {
  const { classes } = useStyles()
  const updatedApps = useAppSelector(state => state.application.recentlyUpdatedApps)
  const [ anchorEl, setAnchorEl ] = useState<HTMLDivElement | null>(null)
  const [ selectedAppUpdate, setSelectedAppUpdate ] = useState({
    app: '',
    updateTime: '',
    version: ''
  })
  const appNotifRefs = useRef<{ [k: string]: any }>({})

  const recentlyUpdatedApps = useMemo(
    () => JSON.parse(localStorage.getItem('appUpdates') || '{}'),
    [ updatedApps ]
  )

  const getAppUpdateByAppKey = (appKey: string) => {
    const update = recentlyUpdatedApps[appKeyToPackageMap[appKey]]

    // Ignore updates older than 24 hours
    if (!update || (update && moment(update.updateTime).isBefore(moment().subtract(24, 'hours')))) return null

    return update
  }

  appNotifRefs.current = useMemo(
    () => Object.keys(recentlyUpdatedApps).reduce((acc, app) => {
      const update = getAppUpdateByAppKey(app)

      if (!update) return acc

      return {
        ...acc,
        [update.app]: null
      }
    },
      {}),
    [ updatedApps ]
  )

  const onPopoverTrigger = (appPackageName: string | null) => (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    setAnchorEl(appPackageName ? appNotifRefs.current[appPackageName] : null)

    const appUpdate = recentlyUpdatedApps[appPackageName || '']
    setSelectedAppUpdate(appUpdate ? appUpdate : { app: '', updateTime: '', version: '' })
  }

  return (
    <>
      <MenuList className={ classes.AppGridContainer }>
        {availableProducts.map(product => (
          <CoreLink
            key={ product.title }
            to={ product?.rootUrl }
            onClick={ onAppSelect }
          >
            <MenuItem className={ classes.AppMenuItem }>
              <div>
                <product.logo style={{ fill: product?.themeColor }} fillColor={ product?.themeColor }
                  className={ classes.AppIcon } />
                <CoreTypography variant='bodyMd'>
                  {product?.title}
                </CoreTypography>
              </div>
              <div>
                {product?.isBeta && (
                  <AppBadge color='grey'>BETA</AppBadge>
                )}
                {product?.isNew && (
                  <AppBadge color='magenta'>NEW</AppBadge>
                )}
              </div>
            </MenuItem>
          </CoreLink>
        ))}
      </MenuList>
      <Popover
        id='updateNotificationPopover'
        open={ !!anchorEl }
        anchorEl={ anchorEl }
        onClose={ () => setAnchorEl(null) }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className={ classes.UpdateNotificationPopover }
        PaperProps={{ onMouseEnter: onPopoverTrigger(selectedAppUpdate.app), onMouseLeave: onPopoverTrigger(null) }}
      >
        <Text small white overflowEllipsis={ false }>
          {appNameToHumanReadable[selectedAppUpdate.app]} was
          updated on {moment(selectedAppUpdate.updateTime).format('MM/DD/YYYY')}.
          Click <a href='/'>HERE</a> to see update information.
        </Text>
      </Popover>
    </>
  )
}
