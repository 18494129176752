import { ILoginContent } from '@extensiv/sdk/hub'
import { NetworkResponse } from '@extensiv/shared-reactcomponents'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

import { showNotification } from '@core/redux/snackbarSlice'
import {
  resendConfirmationCode,
  apiGetOrganizationDetails,
  apiGetLoginContent
} from '@core/services/auth.service'

const defaultState = {
  user: null,
  isAppLoading: true,
  isLoading: false,
  isShowMfaChallenge: false,
  isOpenVerifyEmailDialog: false,
  isOpenForgotPassword: false,
  isCreatePasswordRequired: false,
  newPasswordSessionUserAttributes: null,
  isShowLoginFailedDialog: false,
  isShowChangePasswordDialog: false,
  loginContent: null
}

export const getOrganizationDetails = createAsyncThunk(
  'organizations/details',
  async (payload, { rejectWithValue }) => {
    try {
      const { data }: any = await apiGetOrganizationDetails()
      return data
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const resendCode = createAsyncThunk(
  'auth/resendCode',
  async (email: string, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await resendConfirmationCode(email)
      dispatch(showNotification({
        type: 'success',
        msg: 'Verification code sent successfully!'
      }))
      return response
    }
    catch (err: any) {
      console.log('err', err)
      dispatch(showNotification({
        type: 'error',
        msg: err?.message
      }))
      return rejectWithValue(err)
    }
  }
)

export const getLoginContent = createAsyncThunk<
  NetworkResponse<{ value: ILoginContent }>,
  any
>(
  'login/getLoginContent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiGetLoginContent()

      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const loginSlice: any = createSlice({
  name: 'loginSlice',
  initialState: cloneDeep(defaultState),
  reducers: {
    setIsCreatePasswordRequired: (state, { payload }: any) => {
      state.isCreatePasswordRequired = payload
      return state
    },
    setIsOpenVerifyEmailDialog: (state, { payload }: any) => {
      // console.log(state, payload)
      state.isOpenVerifyEmailDialog = payload
      return state
    },
    setIsShowMfaChallenge: (state, { payload }: any) => {
      state.isShowMfaChallenge = payload
      return state
    },
    setIsOpenForgotPassword: (state, { payload }: any) => {
      state.isOpenForgotPassword = payload
      return state
    },
    setIsShowLoginFailedDialog: (state, { payload }: any) => {
      state.isShowLoginFailedDialog = payload
      return state
    },
    setIsShowChangePasswordDialog: (state, { payload }: any) => {
      state.isShowChangePasswordDialog = payload
      return state
    },
    setNewPasswordSessionUserAttributes: (state, { payload }: any) => {
      delete payload.identities
      state.newPasswordSessionUserAttributes = payload
      return state
    },
    clearStateLogin: (state) => {
      // TODO: we should only clear variable which is required
      state.isAppLoading = false
      state.isLoading = false
      state.isShowMfaChallenge = false
      state.isOpenVerifyEmailDialog = false
      state.isOpenForgotPassword = false
      state.isCreatePasswordRequired = false
      state.newPasswordSessionUserAttributes = null
      state.isShowLoginFailedDialog = false
      state.isShowChangePasswordDialog = false
      state.loginContent = null

      return state
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLoginContent.pending, state => {
        state.isLoading = true
      })
      .addCase(getLoginContent.fulfilled, (state, { payload }: PayloadAction<NetworkResponse<{ value: ILoginContent }>>) => {
        state.isLoading = false

        // @ts-ignore
        state.loginContent = payload?.data?.value || null
      })
      .addCase(getLoginContent.rejected, state => {
        state.isLoading = false
      })
  }
})
export const { setIsShowMfaChallenge, setIsOpenVerifyEmailDialog, setIsOpenForgotPassword, setNewPasswordSessionUserAttributes, setIsCreatePasswordRequired, setIsShowLoginFailedDialog, clearStateLogin, setIsShowChangePasswordDialog } = loginSlice.actions

export default loginSlice.reducer
