import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => ({
  OtpContentContainer: {
    textAlign: 'center'
  },
  OtpInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))
