import { PropsWithChildren } from 'react'

import { CoreTypography } from '@extensiv/shared-reactcomponents'
import { Grid } from '@mui/material'

import ExtensivLogo from '@core/assets/img/extensiv-logo-200.svg'

import { useStyles } from './styles'

export default ({ children }: PropsWithChildren<any>) => {
  const { classes } = useStyles()

  return (
    <Grid container className={ classes.MfaLayoutContainer }>
      <Grid item xs={ 12 } className={ classes.MfaLayoutHeader }>
        <img src={ ExtensivLogo } alt='Extensiv Logo' />
      </Grid>
      <Grid item xs={ 12 } className={ classes.MfaLayoutContent }>
        { children }
      </Grid>
      <Grid item xs={ 12 } className={ classes.MfaLayoutFooter }>
        <CoreTypography variant='bodyMd' className={ classes.HelpText }>
          If you have questions or need help, <br/> contact your administrator.
        </CoreTypography>
      </Grid>
    </Grid>
  )
}
