import { useEffect } from 'react'

import { Typography, Box, Container } from '@mui/material'
import { withRouter } from 'react-router'

import extensivLogoHorizontal from '@core/assets/img/extensiv-white-magenta-logo.svg'
import CssClasses from '@core/assets/styles/css-classes'
import CssVariables from '@core/assets/styles/css-variables'
import ClipPath from '@core/components/ClipPath'
import { postSsoAuthCode } from '@core/services/auth.service'

import { useStyles } from './styles'

export default withRouter((props: any) => {
  const { classes } = useStyles()
  const { classes: commonClasses } = CssClasses()

  useEffect(() => {
    const postCode = async (code: string) => {
      const isSsoSuccess = await postSsoAuthCode(code)

      if (isSsoSuccess) {
        (window as Window).location = '/hub/landing'
      }
    }

    postCode(props.location.search.split('?code=')[1])
  }, [])

  return (
    <Box className={ classes.root } >
      <Box className={ `${classes.innerBox} ${classes.leftBox}` }>
        <Container className={ `${classes.innerContainer} ${classes.leftContainer} ${commonClasses.dFlexCenter}` }>
          <ClipPath top left />
          <span className='loginMessage'>Logging in with SSO...</span>
          <Typography component='span' className={ classes.copyright }>
            Copyright © Extensiv {new Date().getFullYear()}
          </Typography>
        </Container>
      </Box>
      <Box className={ `${classes.innerBox} ${classes.rightBox}` }>
        <span className={ classes.rightBoxLogo }>
          <img src={ extensivLogoHorizontal } alt='Extensiv' />
        </span>
        <Container
          className={ `${classes.innerContainer} ${classes.rightContainer} ${commonClasses.dFlexCenter}` }
        >
          <ClipPath
            bottom
            right={{
              color: CssVariables.orange,
              clipPath: 'polygon(0 0, 0 100%, 100% 0)'
            }}
          />
        </Container>
      </Box>
    </Box >
  )
})
