import { useState, useEffect } from 'react'

import { Text, ExtensivButton } from '@extensiv/shared-reactcomponents'
import { Dialog, DialogTitle, Grid } from '@mui/material'

import { setCurrentAppOutdated } from '@core/redux/appSlice'
import { useAppSelector, useAppDispatch } from '@core/redux/store'
import { appNameToHumanReadable } from '@core/util/commonFunctions'

import useStyles from './styles'

const TIMER_TOTAL = 60
const DELAY_TIMER = 5 * 60 * 1000 // 5 minutes

export default () => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const { newCurrentAppVersionDetails } = useAppSelector((state: any) => state.application)
  const [ timeRemaining, setTimeRemaining ] = useState(TIMER_TOTAL)

  useEffect(() => {
    if (!newCurrentAppVersionDetails) return

    const timer = setInterval(() => {
      setTimeRemaining(timeRemaining - 1)
    }, 1000)

    if (timeRemaining === 0) {
      clearInterval(timer)
      triggerReload()
    }

    return () => {
      clearInterval(timer)
    }
  }, [ newCurrentAppVersionDetails, timeRemaining ])

  if (!newCurrentAppVersionDetails) return null

  // Reset this state and re-trigger the modal in 5 minutes
  const startWaitTimer = async () => {
    await dispatch(setCurrentAppOutdated(null))
    setTimeRemaining(TIMER_TOTAL)

    window.setTimeout(() => {
      dispatch(setCurrentAppOutdated({ detail: newCurrentAppVersionDetails }))
    }, DELAY_TIMER)
  }

  const triggerReload = () => window.location.reload()

  const humanReadableAppName = appNameToHumanReadable[newCurrentAppVersionDetails.app]

  return (
    <Dialog
      open
      onClose={ startWaitTimer }
      maxWidth='md'
      fullWidth
      data-testid='newAppVersionAvailableDialog'
    >
      <DialogTitle>
        New Update
        <span className={ classes.VersionText }>
          {humanReadableAppName} v{newCurrentAppVersionDetails.version}
        </span>
      </DialogTitle>
      <Grid container rowSpacing={ 3.75 } className={ classes.DialogContent }>
        <Grid item xs={ 12 }>
          <Text overflowEllipsis={ false }>
            We&apos;ve detected that you&apos;re using an older version of {humanReadableAppName},
            which means you&apos;re missing out on the latest updates. &nbsp;
            <b>This page will automatically refresh in {timeRemaining} seconds</b> to get you
            on the newest version.
          </Text>
        </Grid>
        { /*
          <Grid item xs={ 12 }>
            <Link href='#' className={ classes.UpdateLink }>
              <Text small bold className={ classes.UpdateLinkText }>
                View Update Details
              </Text>
            </Link>
          </Grid>
        */}
      </Grid>
      <Grid container className={ classes.DialogActions }>
        <Grid item xs={ 2 }>
          <ExtensivButton
            fullWidth
            onClick={ startWaitTimer }
            variant='outlined'
            data-testid='newAppVersionAvailableDialogWaitButton'
          >
            Wait 5 Minutes
          </ExtensivButton>
        </Grid>
        <Grid item xs={ 2 }>
          <ExtensivButton
            color='primary'
            fullWidth
            onClick={ triggerReload }
            data-testid='newAppVersionAvailableDialogReloadButton'
          >
            Refresh Now
          </ExtensivButton>
        </Grid>
      </Grid>
    </Dialog>
  )
}
