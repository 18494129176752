import { MfaTitleInstruction, MfaOtpInput } from "@extensiv/shared-reactcomponents"
import { Grid } from '@mui/material'

import { CORE_APP_ROOT } from "@core/constants/coreRoutes"
import MfaLayout from "@core/containers/MfaLayout"
import { verifyMfaCode } from "@core/services/auth.service"

import { useStyles } from './styles'

export default () => {
  const { classes } = useStyles()

  const onCodeEntered = (otpCode: string, callback: (isSuccess: boolean) => void) => verifyMfaCode(
    otpCode,
    () => {
      (window as Window).location = CORE_APP_ROOT
      callback(true)
    },
    () => callback(false)
  )

  return (
    <MfaLayout>
      <Grid container className={ classes.OtpContentContainer } rowSpacing={ 7 }>
        <Grid item xs={ 12 }>
          <MfaTitleInstruction
            title='Two-factor authentication'
            instruction='Enter the code from your authenticator app'
          />
        </Grid>
        <Grid item xs={ 12 } className={ classes.OtpInputContainer }>
          <MfaOtpInput onCodeEntered={ onCodeEntered } />
        </Grid>
      </Grid>
    </MfaLayout>
  )
}
