import { memo, useState, useMemo, ChangeEvent } from 'react'

import { CoreTypography } from '@extensiv/shared-reactcomponents'
import { faCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, TextField, InputAdornment, IconButton, Typography } from '@mui/material'

import useStyles from './styles'


interface IProps {
  value?: string
  required?: boolean
  onChange: (event: any) => void
  cmpSelector?: string
  enabled: boolean
}

interface IPasswordValueState {
  create_password: string
  confirm_password: string
}

interface IPasswordValidationState {
  isValidCreatePassword: boolean
  isValidConfirmPassword: boolean
  showCreatePassword: boolean
  showConfirmPassword: boolean
}

interface IRegexRuleState {
  hasEightChar: boolean
  hasOneNumber: boolean
  hasUpperCase: boolean
  hasLowerCase: boolean
  hasSpecialChar: boolean
}

export default memo(({ value, required, onChange, cmpSelector = '', enabled }: IProps) => {
  const { classes, cx } = useStyles()
  const [ values, setValues ] = useState<IPasswordValueState>({
    create_password: value ? value : '',
    confirm_password: '',
  })

  const [ passValidation, setPassValidation ] = useState<IPasswordValidationState>({
    isValidCreatePassword: false,
    isValidConfirmPassword: false,
    showCreatePassword: false,
    showConfirmPassword: false,
  })

  const [ regexRules, setRegexRules ] = useState<IRegexRuleState>({
    hasEightChar: false,
    hasOneNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialChar: false,
  })

  const isShowPasswordMismatchError = useMemo(() => {
    return values.confirm_password !== ''
      && !passValidation.isValidConfirmPassword
      && values.confirm_password !== values.create_password
  }, [ values ])

  const handleChange = (inputKey: keyof IPasswordValueState) => (event: ChangeEvent<HTMLInputElement>) => {
    const inputVal = event.target.value
    let finalPasswordValue = ''
    let passValidationObj = { ...passValidation }

    const valuesObj = { ...values, [inputKey]: inputVal }

    if (inputKey === 'create_password') {
      const regexAtLeastEightChar = /.{8,}/
      const regexAtLeastOneNumber = /\d/
      const regexAtLeastOneUpperCase = /(?=.*[A-Z])/
      const regexAtLeastOneLowerCase = /(?=.*[a-z])/
      const regexAtLeastOneSpecialChar = /(?=.*?[#&!$])/

      const hasEightChar = regexAtLeastEightChar.test(inputVal)
      const hasOneNumber = regexAtLeastOneNumber.test(inputVal)
      const hasUpperCase = regexAtLeastOneUpperCase.test(inputVal)
      const hasLowerCase = regexAtLeastOneLowerCase.test(inputVal)
      const hasSpecialChar = regexAtLeastOneSpecialChar.test(inputVal)

      setRegexRules({
        ...regexRules,
        ...{ hasEightChar, hasOneNumber, hasUpperCase, hasLowerCase, hasSpecialChar }
      })
      passValidationObj = {
        ...passValidationObj,
        isValidCreatePassword: (hasEightChar && hasOneNumber && hasUpperCase && hasLowerCase && hasSpecialChar)
      }
    }

    const isSamePassword = (valuesObj.create_password === valuesObj.confirm_password)
    passValidationObj = { ...passValidationObj, isValidConfirmPassword: isSamePassword }

    if (passValidationObj.isValidCreatePassword && passValidationObj.isValidConfirmPassword) {
      finalPasswordValue = valuesObj.create_password
    }

    setPassValidation(passValidationObj)
    setValues(valuesObj)
    onChange({ target: { value: finalPasswordValue } })
  }

  const handleClickShowPassword = (stateKey: keyof IPasswordValidationState) => () => {
    setPassValidation({ ...passValidation, [stateKey]: !passValidation[stateKey] })
  }

  return (
    <Box width={ 1 }>
      <TextField
        required={ required }
        disabled={ !enabled }
        label='New password'
        type={ passValidation.showCreatePassword ? 'text' : 'password' }
        style={{ marginBottom: 0 }}
        variant='outlined'
        size='medium'
        color='secondary'
        autoComplete='new-password'
        value={ values.create_password }
        onChange={ handleChange('create_password') }
        error={ values.create_password !== '' && !passValidation.isValidCreatePassword }
        data-testid={ `${cmpSelector}PasswordTxtFld` }
        id={ `${cmpSelector}PasswordTxtFldInput` }
        inputProps={{
          'data-testid': `${cmpSelector}PasswordTxtFldInput`,
        }}
        InputProps={{
          sx: { height: '56px' },
          endAdornment:
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={ handleClickShowPassword('showCreatePassword') }
                edge='end'
                data-testid={ `${cmpSelector}CreatePasswordToggleVisibilityIconBtn` }
              >
                {passValidation.showCreatePassword
                  ? <Visibility data-testid={ `${cmpSelector}CreatePasswordVisibilityIcon` } />
                  : <VisibilityOff data-testid={ `${cmpSelector}CreatePasswordInvisibleIcon` } />
                }
              </IconButton>
            </InputAdornment>
        }}
      />
      <Typography className={ `${classes.regexRules}` }>
        <CoreTypography
          variant='bodyMd'
          className={ cx({ RegexSuccess: (regexRules.hasEightChar || !enabled) }) }
          data-testid={ `${cmpSelector}AtLeast8CharLongSpan` }
        >
          <FontAwesomeIcon
            icon={ regexRules.hasEightChar ? faCheckCircle : faCircle }
            className={ classes.regexRulesIcon }
          />
          At least 8 characters long
        </CoreTypography>
        <br />
        <CoreTypography
          variant='bodyMd'
          className={ cx({ RegexSuccess: (regexRules.hasUpperCase || !enabled) }) }
          data-testid={ `${cmpSelector}AtLeast1CapitalSpan` }
        >
          <FontAwesomeIcon
            icon={ regexRules.hasUpperCase ? faCheckCircle : faCircle }
            className={ classes.regexRulesIcon }
          />
          At least 1 capital letter
        </CoreTypography>
        <br />
        <CoreTypography
          variant='bodyMd'
          className={ cx({ RegexSuccess: (regexRules.hasLowerCase || !enabled) }) }
          data-testid={ `${cmpSelector}AtLeast1SmallLatterSpan` }
        >
          <FontAwesomeIcon
            icon={ regexRules.hasLowerCase ? faCheckCircle : faCircle }
            className={ classes.regexRulesIcon }
          />
          At least 1 lowercase letter
        </CoreTypography>
        <br />
        <CoreTypography
          variant='bodyMd'
          className={ cx({ RegexSuccess: (regexRules.hasOneNumber || !enabled) }) }
          data-testid={ `${cmpSelector}AtLeast1NumberSpan` }
        >
          <FontAwesomeIcon
            icon={ regexRules.hasOneNumber ? faCheckCircle : faCircle }
            className={ classes.regexRulesIcon }
          />
          At least 1 number
        </CoreTypography>
        <br />
        <CoreTypography
          variant='bodyMd'
          className={ cx({ RegexSuccess: (regexRules.hasSpecialChar || !enabled) }) }
          data-testid={ `${cmpSelector}AtLeast1SpecialCharSpan` }
        >
          <FontAwesomeIcon
            icon={ regexRules.hasSpecialChar ? faCheckCircle : faCircle }
            className={ classes.regexRulesIcon }
          />
          At least 1 special character (#, &, !, $)
        </CoreTypography>
      </Typography>
      <TextField
        required={ required }
        disabled={ !enabled }
        type={ passValidation.showConfirmPassword ? 'text' : 'password' }
        label='Confirm new password'
        variant='outlined'
        color='secondary'
        size='medium'
        autoComplete='new-confirm-password'
        onChange={ handleChange('confirm_password') }
        value={ values.confirm_password }
        error={ values.confirm_password !== '' && !passValidation.isValidConfirmPassword }
        helperText={ isShowPasswordMismatchError ? "Passwords don't match" : '' }
        data-testid={ `${cmpSelector}ConfirmPasswordTxtFld` }
        id={ `${cmpSelector}ConfirmPasswordTxtFldInput` }
        inputProps={{
          'data-testid': `${cmpSelector}ConfirmPasswordTxtFldInput`,
        }}
        FormHelperTextProps={ isShowPasswordMismatchError ? {
          sx: {
            right: 'auto !important',
            left: '16px !important',
            bottom: '-8px !important'
          }
        } : {} }
        InputProps={{
          sx: { height: '56px', marginBottom: isShowPasswordMismatchError ? '10px' : '' },
          endAdornment:
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={ handleClickShowPassword('showConfirmPassword') }
                edge='end'
                data-testid={ `${cmpSelector}ConfirmPasswordToggleVisibilityIconBtn` }
              >
                {passValidation.showConfirmPassword
                  ? <Visibility data-testid={ `${cmpSelector}ConfirmPasswordVisibilityIcon` } />
                  : <VisibilityOff data-testid={ `${cmpSelector}ConfirmPasswordInvisibleIcon` } />
                }
              </IconButton>
            </InputAdornment>
        }}
      />
    </Box>
  )
})
