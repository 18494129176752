import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  AlertLink: {
    marginRight: '24px',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '13px'
  },
  SetupStepsContent: {
    marginTop: '56px'
  }
}))
