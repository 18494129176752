import { Route, Redirect } from 'react-router-dom'

import { CORE_APP_ROOT } from '@core/constants/coreRoutes'
import { useAppSelector } from '@core/redux/store'
import { isAuthRoutes } from '@core/services/auth.service'
import { LocationState } from "@core/types/Location"

const PublicRoute: any = ({ component, ...rest }: { component: any }) => {
  const Component = component
  const { cognitoSession } = useAppSelector((state: any) => state.auth)
  const isAuthenticated = cognitoSession && cognitoSession.idToken ? true : false

  return (
    <Route
      { ...rest }
      render={ ({ location }) => {
        if (isAuthRoutes(location.pathname) && !isAuthenticated) {
          return <Component { ...location } />
        }

        if(location.state) {
          const { from } = (location.state as LocationState)

          if (from) return <Redirect to={{ pathname: from }} />
        }

        return <Redirect to={{ pathname: CORE_APP_ROOT }} />
      } }
    />
  )
}

export default PublicRoute
