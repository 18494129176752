export default {
  fontFamilyLato: 'Lato',
  headerHeight: 64,
  drawerWidth: 175,
  // Colors
  white: '#FFFFFF',
  offWhite: '#F5F5F5',
  black: '#000000',
  primary: '#008895',
  secondary: '#ed40a9',
  secondaryLight: '#93e42c',
  secondaryDark: '#700042',
  cardTitleBackground: '#839fb0',
  paleBlueGrey: '#91A8B8',
  accent: '#F48831',
  labelLight: '#888888',
  labelDark: '#333333',
  borderColor: '#BBBBBB',
  pink: '#F494DA',
  pinkLight: '#FFDEDE',
  pinkHover: '#EFCFCF',
  orange: '#FF4700',
  green: '#81c925',
  greenLight: '#93e42c',
  tableRow: '#C1E9ED',
  columnHeader: '#d4dde4',
  tableFontColor: '#222222',
  darkGray: '#394859',

  headerBackground: '#212121',
  headerTabsBackground: '#212121',
}
//
