import { ReactNode, useEffect, useState } from 'react'

import { Spinner } from '@extensiv/shared-reactcomponents'
import * as globalUtility from '@extensiv-app/utility'
import Backdrop from '@mui/material/Backdrop'
import { isEqual } from 'lodash'
import { useHistory } from 'react-router-dom'

import NewVersionUpdateModal from '@core/components/NewVersionUpdateModal'
import { reverseLookupRoutes } from '@core/constants/appRoutes'
import customEvents from '@core/constants/customEvents'
import { clearStateApplication, setActiveProducts, setSelectedApp } from '@core/redux/appSlice'
import { showNotification } from '@core/redux/snackbarSlice'
import { useAppDispatch, useAppSelector } from '@core/redux/store'

import Header from './Header'
import useStyles from './styles'


interface IProps {
  children: ReactNode
}

export default ({ children }: IProps) => {
  const { classes } = useStyles()
  const [ open ] = useState(true)
  const dispatch = useAppDispatch()
  const { isShowBackdrop } = useAppSelector((state: any) => state.application)

  // =========== Change application based on url =============
  const history = useHistory()
  const pathNames = history.location.pathname.split('/').filter((x: any) => x)
  const appPath = pathNames[0]

  useEffect(() => {
    return () => {
      dispatch(clearStateApplication())
    }
  }, [])

  useEffect(() => {
    const lookedUpApp = reverseLookupRoutes[`/${appPath}`]

    if (!isEqual(globalUtility?.globalSelectedAppName?.getValue(), lookedUpApp)) {
      globalUtility.globalSelectedAppName.next(lookedUpApp)
    }
  }, [ appPath ])

  useEffect(() => {
    const selectedAppSubscription = globalUtility.globalSelectedAppName.subscribe((appName: any) => {
      dispatch(setSelectedApp(appName))
    })

    const activeProductsSubscription = globalUtility.globalActiveProducts.subscribe((activeProducts: any) => {
      dispatch(setActiveProducts(activeProducts))
    })

    const notificationListener = (e: CustomEvent) => {
      dispatch(showNotification(e.detail))
    }

    window.addEventListener(customEvents.NOTIFICATION_MESSAGE, notificationListener as EventListener)

    return () => {
      window.removeEventListener(customEvents.NOTIFICATION_MESSAGE, notificationListener as EventListener)
      selectedAppSubscription.unsubscribe()
      activeProductsSubscription.unsubscribe()
    }
  }, [])

  return (
    <>
      {(isShowBackdrop) && (
        <Backdrop className={ classes.backdrop } open>
          <Spinner withBackdrop={ true } />
        </Backdrop>
      )}
      <Header open={ open } />

      <NewVersionUpdateModal />
    </>
  )
}
