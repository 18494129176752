import __html from '../../../../public/DirectLogin/direct_login.html'

const template = { __html: __html }

const OopsPage = () => {
  return (
    <div className="oops-page">
      <span dangerouslySetInnerHTML={ template } />
    </div>
  )
}

export default OopsPage
