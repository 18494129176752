import { makeStyles } from 'tss-react/mui'

import CssVariables from '@core/assets/styles/css-variables'

export default makeStyles()(() => ({
  container: {
    width: 537,
    height: 278,
    flexDirection: 'column'
  },
  logo: {
    marginBottom: 25,
    width: 220,
    '& img': {
      width: '100%'
    }
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: CssVariables.labelDark
  },
  infoText: {
    fontSize: 10,
    color: CssVariables.labelDark,
    marginBottom: 40,
  },
  redirectText: {
    marginBottom: 20,
    fontSize: 16,
  },
}))
