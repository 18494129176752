import { BaseSyntheticEvent, useState } from 'react'

import { ExtensivButton } from '@extensiv/shared-reactcomponents'
import { ExitToApp } from '@mui/icons-material'
import { TextField, Typography, Box, Container, Divider } from '@mui/material'

import extensivLogoHorizontal from '@core/assets/img/extensive-logo(mono)-200.svg'
import CssClasses from '@core/assets/styles/css-classes'
import CssVariables from '@core/assets/styles/css-variables'
import ClipPath from '@core/components/ClipPath'
import { register } from '@core/services/auth.service'

import PasswordComponent from '../PasswordComponent'

import { useStyles } from './styles'
import SuccessDialog from './SuccessDialog'



interface FormValuesState {
  email: string
  password: string
}

export default () => {
  const { classes } = useStyles()
  const { classes: commonClasses } = CssClasses()
  const [ formValues, setFormValues ] = useState<FormValuesState>({
    email: '',
    password: '',
  })
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isShowSuccessMsg, setIsShowSuccessMsg ] = useState(false)
  const [ resError, setResError ]: any = useState(null)

  const handleChange = (inputKey: keyof FormValuesState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = event.target.value
    setFormValues({ ...formValues, [inputKey]: inputVal })
  }

  const handleSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const { email, password } = formValues
      if (email !== '' && password !== '') {
        const authResult = await register(email, password)
        console.log('usr', authResult)
        setIsShowSuccessMsg(true)
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setResError(error)
    }
  }

  return (
    <Box className={ classes.root } >
      <Box className={ `${classes.innerBox} ${classes.leftBox}` }>
        <Container className={ `${classes.innerContainer} ${classes.leftContainer} ${commonClasses.dFlexCenter}` }>
          <ClipPath top left />
          <form className={ classes.form } onSubmit={ handleSubmit }>
            <Typography className={ classes.formTitle }>
              Welcome to Extensiv
            </Typography>
            <TextField
              id='email'
              label='Email Address'
              helperText=''
              variant='outlined'
              color='secondary'
              autoComplete='username'
              onChange={ handleChange('email') }
              required
              className={ commonClasses.textFieldCustom }
            />
            <Divider style={{ marginTop: 16, marginBottom: 30 }} />
            <PasswordComponent
              onChange={ handleChange('password') }
              enabled
              required
            />
            {resError && (
              <div className={ classes.errorNotConfirmed }>
                <Typography color='error'>{resError?.message}</Typography>
              </div>
            )}
            <ExtensivButton
              type='submit'
              id='SignUpButton'
              color='secondary'
              startIcon={ <ExitToApp /> }
              isLoading={ isLoading }
              disabled={ formValues.email === '' || formValues.password === '' }
            >
              Sign Up
            </ExtensivButton>
          </form>
          <Typography component='span' className={ classes.copyright }>
            Copyright © Extensiv {new Date().getFullYear()}
          </Typography>
        </Container>
      </Box>
      <Box className={ `${classes.innerBox} ${classes.rightBox}` }>
        <span className={ classes.rightBoxLogo }>
          <img src={ extensivLogoHorizontal } alt='Extensiv' />
        </span>
        <Container className={ `${classes.innerContainer} ${classes.rightContainer} ${commonClasses.dFlexCenter}` }>
          <ClipPath
            bottom
            right={{
              color: CssVariables.primary,
              clipPath: 'polygon(0 0, 0 100%, 100% 0)'
            }}
          />
          <span>Marketing Message</span>
        </Container>
      </Box>
      {isShowSuccessMsg && <SuccessDialog />}
    </Box >
  )
}
