import { Core as permissions } from '@extensiv/sdk/permissions'
import { hasPermission } from '@extensiv-app/utility'
import createDOMPurify from 'dompurify'
import { debounce } from 'lodash'

class DOMPurifyPassThrough {
  constructor(props: any) {
    return createDOMPurify(props)
  }
}

window.DOMPurify = DOMPurifyPassThrough

export enum SalesforceChatDetail {
  ORGKEY = 'HubKey',
  APP = 'HubProduct',
  FIRSTNAME = 'FirstName',
  LASTNAME = 'LastName',
  EMAIL = 'Email'
}

class SalesforceChat {
  isInitialized = false
  isChatButtonVisible = true
  buttonSearchTimeout: ReturnType<typeof setTimeout> | undefined = undefined
  chatDetails = [
    {
      label: 'HubKey',
      value: 'TestOrg',
      transcriptFields: [ 'HubKey__c' ],
      displayToAgent: false
    },
    {
      label: 'HubProduct',
      value: 'TestProduct',
      transcriptFields: [ 'HubProduct__c' ],
      displayToAgent: false
    },
    {
      label: 'First Name',
      name: 'FirstName',
      value: "TestFirst",
      transcriptFields: [ 'FirstName__c' ],
      displayToAgent: false
    },
    {
      label: 'Last Name',
      name: 'LastName',
      value: 'TestLast',
      transcriptFields: [ 'LastName__c' ],
      displayToAgent: false
    },
    {
      label: 'Email',
      name: 'Email',
      value: 'TestEmail',
      transcriptFields: [ 'Email__c' ],
      displayToAgent: false
    }
  ]

  initializeChat = () => {
    this.debouncedInitializeChat(this.chatDetails)
  }

  debouncedInitializeChat = debounce((chatDetails) => {
    if (!hasPermission([ permissions.Base_SupportChat_View, permissions.CrossOrg_SupportChat_View ], false)) return

    const initChat = () => {
      window.embedded_svc.settings.displayHelpButton = true
      window.embedded_svc.settings.language = ''
      window.embedded_svc.settings.extraPrechatFormDetails = chatDetails
      window.embedded_svc.settings.enabledFeatures = [ 'LiveAgent' ]
      window.embedded_svc.settings.entryFeature = 'LiveAgent'
      window.embedded_svc.settings.smallCompanyLogoImgURL = "https://www.extensiv.com/hubfs/Extensiv_april_2022/Images/favicon.png"
      window.embedded_svc.settings.defaultMinimizedText = "Hello"
      window.embedded_svc.init(
        'https://3plcentral.my.salesforce.com',
        'https://3plcentral.my.site.com/support',
        'https://service.force.com',
        '00D300000006eMr',
        'Omni_Chat',
        {
          baseLiveAgentContentURL: 'https://c.la1-core1.sfdc-yfeipo.salesforceliveagent.com/content',
          deploymentId: '572Pp0000000gKD',
          buttonId: '573Pp000000SMyT',
          baseLiveAgentURL: 'https://d.la1-core1.sfdc-yfeipo.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04IPp0000003R6vMAE_18badb1cc3f',
          isOfflineSupportEnabled: false
        }
      )
    }

    if (!window.embedded_svc) {
      const s = document.createElement('script')
      s.setAttribute('src', 'https://3plcentral.my.salesforce.com/embeddedservice/5.0/esw.min.js')
      s.onload = initChat
      document.head.appendChild(s)
    } else {
      initChat()
    }

    this.isInitialized = true
    this.setChatButtonVisibility(this.isChatButtonVisible)
  }, 1000)

  setFormDetailValue = (key: SalesforceChatDetail, value?: string) => {
    this.chatDetails = this.chatDetails?.map((detail: any) => {
      if (detail.label != key && detail.name != key) return detail

      let v = value
      if (key == SalesforceChatDetail.APP && value) {
        v = this.mapAppName(value)
      }

      return { ...detail, value: v }
    })
  }

  setChatButtonVisibility = (visible: boolean) => {
    // If we're trying to set the button to visible, but the user doesnt have
    // perms, do nothing
    if (visible && !hasPermission([ permissions.Base_SupportChat_View, permissions.CrossOrg_SupportChat_View ], false)) return

    this.isChatButtonVisible = visible
    clearTimeout(this.buttonSearchTimeout)

    const chatButton = document.getElementsByClassName('embeddedServiceHelpButton')[0]
    if (chatButton) {
      chatButton.setAttribute('style', `display: ${visible ? 'block' : 'none'}`)
    } else if (!visible) {
      console.log('button not found, setting timeout')
      this.buttonSearchTimeout = setTimeout(() => this.setChatButtonVisibility(visible), 500)
    }
  }

  mapAppName = (appName: string) => {
    const mapping: Record<string, string> = {
      'billing': 'billing-master',
      'top-shelf-classic-admin': 'warehouse-manager-classic-admin-wrapper',
      'top-shelf-unlimited-admin': 'warehouse-manager-unlimited-admin-wrapper',
      'top-shelf-customer-portal': 'warehouse-manager-customer-portal-wrapper',
      'warehouse-manager-integrations': 'warehouse-manager-integrations-wrapper',
      'top-shelf-mobile': 'warehouse-manager-mobile-wrapper',
      'warehouse-manager-account-manager': 'warehouse-manager-account-manager-wrapper',
      'cart-rover': 'integration-manager-wrapper',
      '4pl-network': 'network-manager',
      'wms': '3pl-warehouse-manager-wrapper',
      'smartscan': 'smartscan-wrapper',
      'wms-management-console': 'management-console-wrapper',
      'wms-signup': 'signup-wrapper',
      'skubana': 'order-manager-wrapper',
      'core-admin': 'hub',
      'admin': 'admin',
      'fulfillment-marketplace': 'fulfillment-marketplace',
      'accounting-integration': 'accounting-integration-wrapper',
      'business-analytics': 'business-analytics'
    }

    return `@extensiv-app/${mapping[appName]}`
  }
}

export const sfChat = new SalesforceChat()
