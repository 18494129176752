import { makeStyles } from 'tss-react/mui'

import backgroundImage from '@core/assets/img/railyard.png'
import CssVariables from '@core/assets/styles/css-variables'

export const useStyles = makeStyles()(() => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: CssVariables.secondaryDark
  },
  innerBox: {
    display: 'flex',
    minHeight: 600,
    width: '100%',
    justifyContent: 'center',
  },
  leftBox: {
    paddingRight: 0
  },
  rightBox: {
    paddingLeft: 0,
    position: 'relative',
  },
  rightBoxLogo: {
    position: 'absolute',
    left: 50,
    top: -50,
    width: 180,
    transform: 'translate(-5%, -100%)',
    // TODO: need to move common
    '& img': {
      width: '100%'
    }
  },
  innerContainer: {
    minHeight: 455,
    maxHeight: 600,
    maxWidth: 600,
  },
  leftContainer: {
    marginRight: 0,
    backgroundColor: CssVariables.white,
    position: 'relative',
    flex: 1,
    flexDirection: 'column',
    '& span.loginMessage': {
      fontFamily: CssVariables.fontFamilyLato,
      color: CssVariables.secondary,
      fontWeight: 900,
      fontSize: '18px'
    }
  },
  leftContainerLogo: {
    height: 95,
    '& img': {
      height: '100%'
    }
  },
  rightContainer: {
    alignItems: 'center',
    marginLeft: 0,
    position: 'relative',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover'
  },
  form: {
    position: 'relative',
    maxWidth: 300,
    '& button[type="submit"]': {
      marginTop: 35
    }
  },
  formTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 60,
    color: CssVariables.secondary
  },
  copyright: {
    color: CssVariables.white,
    position: 'absolute',
    bottom: -25,
    left: 0,
    fontSize: 10,
  },
  errorNotConfirmed: {
    position: 'absolute',
    bottom: 44,
    right: 0,
    '& .MuiTypography-root': {
      fontSize: 12
    },
    '& .MuiLink-root': {
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  }
}))
