export enum TClipPathPositions {
	top = 'top',
	bottom = 'bottom',
	left = 'left',
	right = 'right'
}

export type TClipPathDetails = {
  color: string,
  clipPath: string
}

export type TClipPathProp = boolean | string | TClipPathDetails

export type TClipPathProps = {
	[key in TClipPathPositions]?: TClipPathProp
}
