import { getClipPathProp } from './func'
import { useStyles } from './styles'
import { TClipPathProps } from './types'

// !important: This component must be wrapped with either relatively or absolutely positioned element in order to work properly
export default (props: TClipPathProps) => {
  if (!Object.keys(props).length) return null

  const styleProps = {
    top: getClipPathProp(props.top),
    bottom: getClipPathProp(props.bottom),
    left: getClipPathProp(props.left),
    right: getClipPathProp(props.right),
  }

  const { classes } = useStyles(styleProps)

  return <>
    {Boolean(props.top) && <div className={ `${classes.polygon} ${classes.topPolygon}` } />}
    {Boolean(props.bottom) && <div className={ `${classes.polygon} ${classes.bottomPolygon}` } />}
    {Boolean(props.left) && <div className={ `${classes.polygon} ${classes.leftPolygon}` }/>}
    {Boolean(props.right) && <div className={ `${classes.polygon} ${classes.rightPolygon}` } />}
  </>
}
