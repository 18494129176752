import { CoreLink } from '@extensiv/shared-reactcomponents'
import { Switch, BrowserRouter, Redirect, Route } from 'react-router-dom'

import { routes as appRoutes } from '@core/constants/appRoutes'
import { AUTH_ROUTES, CORE_APP_ROOT, LINK_OM, LOGIN_PREVIEW, MFA_SETUP } from '@core/constants/coreRoutes'
import CreatePasswordPage from '@core/containers/Auth/CreatePasswordPage'
import ForgotPasswordPage from '@core/containers/Auth/ForgotPasswordPage'
import LoginPage from '@core/containers/Auth/LoginPage'
import OopsPage from '@core/containers/Auth/OopsPage'
import RegisterPage from '@core/containers/Auth/RegisterPage'
import SsoPage from '@core/containers/Auth/SsoPage'
import LinkOMPage from '@core/containers/LinkOMPage'
import MfaSetupPage from '@core/containers/MfaSetupPage'

import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'


export default () => (
  <BrowserRouter>
    <Route exact path='/'>
      <Redirect to={ AUTH_ROUTES.LOGIN } />
    </Route>
    <Switch>
      <PublicRoute exact path={ AUTH_ROUTES.LOGIN } component={ LoginPage } />
      <PublicRoute exact path={ LOGIN_PREVIEW } component={ LoginPage } />
      <PublicRoute exact path={ AUTH_ROUTES.RESET_PASSWORD } component={ ForgotPasswordPage } />
      <PublicRoute exact path={ AUTH_ROUTES.CREATE_PASSWORD } component={ CreatePasswordPage } />
      <PublicRoute exact path={ AUTH_ROUTES.REGISTER } component={ RegisterPage } />
      <PublicRoute exact path={ AUTH_ROUTES.OOPS } component={ OopsPage } />
      <PublicRoute exact path={ AUTH_ROUTES.SSO } component={ SsoPage } />
      { Object.values(appRoutes).map(path => (
        <PrivateRoute exact path={ path } component={ HubAppPage } key={ path } />
      ))}
      <PrivateRoute exact path={ MFA_SETUP } component={ MfaSetupPage } />
      <PrivateRoute exact path={ LINK_OM } component={ LinkOMPage } />
      <PrivateRoute exact restricted={ false } path={ CORE_APP_ROOT } component={ Empty } />
      <PrivateRoute component={ NoMatch } />
    </Switch>
  </BrowserRouter>
)

const Empty = () => <></>

const NoMatch = ({ location }: any) => (
  <div>
    <h3>
      No match for <code>{ location.pathname }</code>
      <br />
      <CoreLink to={ AUTH_ROUTES.LOGIN }>Back To LoginPage</CoreLink>
    </h3>
  </div>
)

// An empty component for routes that match other apps
const HubAppPage = () => null
