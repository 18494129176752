import { useState, useEffect } from 'react'

import { ExtensivButton, CoreTypography, AppBadge } from '@extensiv/shared-reactcomponents'
import { environments } from '@extensiv-app/utility'
import { faHexagonExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import { Check, ErrorOutline } from '@mui/icons-material'
import { TextField, Grid, useMediaQuery, Alert, AlertTitle } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import * as yup from 'yup'

import ExtensivLogo from '@core/assets/img/extensiv-logo-200.svg'
import defaultLoginImage from '@core/assets/img/login.jpg'
import { AUTH_ROUTES, LOGIN_PREVIEW } from '@core/constants/coreRoutes'
import { login } from '@core/redux/authSlice'
import { clearStateLogin, getLoginContent } from '@core/redux/loginSlice'
import { useAppDispatch, useAppSelector } from '@core/redux/store'

import MfaVerificationScreen from './MfaVerificationScreen'
import { useStyles } from './styles'


const loginFormSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).max(32).required(),
})



export default () => {
  const cmpSelector = 'Login'
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const {
    isLoading,
    isCreatePasswordRequired,
    isShowMfaChallenge,
    loginContent
  } = useAppSelector((state: any) => state.login)

  const { isLoading: isAuthLoading } = useAppSelector((state: any) => state.auth)

  const { pathname } = useLocation()

  const [ isShowAuthFailedException, setIsShowAuthFailedException ] = useState<boolean>(false)

  // @ts-ignore
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  // @ts-ignore
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const {
    register,
    formState: { errors, isValid, dirtyFields },
    getValues
  } = useForm({
    resolver: yupResolver(loginFormSchema),
    mode: 'all'
  })

  useEffect(() => {
    dispatch(getLoginContent({}))

    return () => {
      dispatch(clearStateLogin())
    }
  }, [])

  useEffect(() => {
    if (isCreatePasswordRequired) {
      history.push(AUTH_ROUTES.CREATE_PASSWORD, { type: 'newPasswordRequired' })
    }
  }, [ isCreatePasswordRequired ])

  const handleLogin = async () => {
    if (pathname === LOGIN_PREVIEW) return

    const [ email, password ] = getValues([ 'email', 'password' ])
    const { type, payload } = await dispatch(login({ email, password }))

    if (type.endsWith('rejected') && payload.code === 'NotAuthorizedException') {
      setIsShowAuthFailedException(true)
    }
  }

  const getEnvSpecificSettings = (env?: string) => {
    interface IEnvSettings {
      overlayColor: string
      badgeColor?: 'grey' | 'magenta'
      imageSaturation?: number
    }

    const envSpecificSettings: Record<string, IEnvSettings> = {
      'LOCAL': {
        overlayColor: '#00707A80'
      },
      'DEV': {
        overlayColor: '#00707A80'
      },
      'QA': {
        overlayColor: '#ED40A966'
      },
      'QA2': {
        overlayColor: '#FF9E184D'
      },
      'QA3': {
        overlayColor: '#FF510066'
      },
      'QA4': {
        overlayColor: '#FF510066'
      },
      'STAGING': {
        overlayColor: '#FF510066'
      },
      'SANDBOX': {
        badgeColor: 'magenta',
        overlayColor: '#4153644D',
        imageSaturation: 0
      }
    }

    return envSpecificSettings[env as keyof typeof envSpecificSettings] || envSpecificSettings['QA']
  }

  const displayLoginContent = () => {
    if (pathname === LOGIN_PREVIEW) {
      const previewContent = sessionStorage.getItem('loginPreview')

      if (!previewContent) return

      const { contentType, loginContent } = JSON.parse(previewContent)
      const content = atob(loginContent || '')

      if (contentType === 'html') {
        return (
          <Grid item xs={ 12 } md={ 8 }>
            <div
              dangerouslySetInnerHTML={{ __html: content || '' }}
              style={{ width: '100%' }}
            />
          </Grid>
        )
      }

      return (
        <Grid item xs={ 12 } md={ 8 } sx={{ display: 'flex', position: 'relative' }}>
          <div
            className={ classes.ImageOverlay }
            style={{ backgroundColor: environments.STAGE !== 'PROD' ? getEnvSpecificSettings(environments.STAGE).overlayColor : 'transparent' }}
          />
          <Grid
            container
            sx={{
              background: `url(${contentType === 'image' ? content : defaultLoginImage})`,
              filter: environments.STAGE !== 'PROD' ? `grayscale(${1 - (getEnvSpecificSettings(environments.STAGE).imageSaturation || 0.15)})` : ''
            }}
            className={ classes.LoginContent }
          />
        </Grid>
      )
    }

    if (loginContent?.loginContentHtml) {
      return (
        <Grid item xs={ 12 } md={ 8 }>
          <div
            dangerouslySetInnerHTML={{ __html: loginContent.loginContentHtml }}
            style={{ width: '100%' }}
          />
        </Grid>
      )
    }

    return (
      <Grid item xs={ 12 } md={ 8 } sx={{ display: 'flex', position: 'relative' }}>
        <div
          className={ classes.ImageOverlay }
          style={{ backgroundColor: environments.STAGE !== 'PROD' ? getEnvSpecificSettings(environments.STAGE).overlayColor : 'transparent' }}
        />
        <Grid
          container
          sx={{
            background: `url(${loginContent?.loginContentImageUrl ? loginContent.loginContentImageUrl : defaultLoginImage})`,
            filter: environments.STAGE !== 'PROD' ? `grayscale(${1 - (getEnvSpecificSettings(environments.STAGE).imageSaturation || 0.15)})` : ''
          }}
          className={ classes.LoginContent }
        />
      </Grid>
    )
  }

  if (isShowMfaChallenge) return <MfaVerificationScreen />

  return (
    <Grid container className={ classes.PageContainer } { ...(isMobile ? { sx: { flexDirection: 'column' } } : {}) }>
      <Grid
        item
        className={ classes.LoginFormLeftContainer }
        xs={ 12 }
        md={ 5 }
        lg={ 4 }
      >
        <Grid container className={ classes.LoginSectionContainer } { ...(isMobile && !isSmall ? { sx: { height: 'auto !important' } } : {}) }>
          <Grid item xs={ 12 } className={ classes.Logo } sx={ isSmall ? { left: 0, right: 0, textAlign: 'center' } : {} }>
            <div>
              <img src={ ExtensivLogo } alt='Extensiv Logo' />
            </div>
            {environments.STAGE !== 'PROD' && (
              <AppBadge color={ getEnvSpecificSettings(environments.STAGE).badgeColor || 'grey' } style={ isSmall ? {} : { marginLeft: '42px' } }>
                {environments.STAGE}
              </AppBadge>
            )}
          </Grid>
          <Grid item xs={ 12 } className={ classes.CenteredFormContainer }>
            <Grid container sx={{ justifyContent: 'center' }}>
              <Grid
                item
                xs={ 12 }
                className={ classes.LoginForm }
                sx={
                  isSmall
                    ? { textAlign: 'center' }
                    : isMobile
                      ? { marginTop: '125px', marginBottom: '35px' }
                      : {}
                }
              >
                <Grid container rowSpacing={ 5 }>
                  <Grid item xs={ 12 }>
                    <CoreTypography variant='headingXl'>
                      Log in
                    </CoreTypography>
                  </Grid>
                  <Grid item xs={ 12 }>
                    <Grid container rowSpacing={ 2 }>
                      <Grid item xs={ 12 }>
                        <TextField
                          { ...register('email') }
                          label='Email'
                          variant='outlined'
                          error={ !!(errors.email?.message) }
                          helperText={ errors.email?.message }
                          autoComplete='username'
                          required
                          data-testid={ `${cmpSelector}EmailTxtFld` }
                          id={ `${cmpSelector}EmailTxtFldInput` }
                          InputProps={{
                            sx: { height: '56px' },
                            endAdornment: errors?.email?.message
                              ? (<ErrorOutline data-testid={ `${cmpSelector}EmailTxtFldErrorIcon` } color='error' />)
                              : (!!(dirtyFields?.email) && <Check data-testid={ `${cmpSelector}EmailTxtFldValidIcon` } color='primary' />)
                          }}
                        />
                      </Grid>
                      <Grid item xs={ 12 }>
                        <TextField
                          { ...register('password') }
                          label='Password'
                          error={ !!(errors.password?.message) }
                          helperText={ errors.password?.message }
                          variant='outlined'
                          type='password'
                          autoComplete='current-password'
                          required
                          data-testid={ `${cmpSelector}PasswordTxtFld` }
                          id={ `${cmpSelector}PasswordTxtFldInput` }
                          InputProps={{
                            sx: { height: '56px' },
                            endAdornment: errors?.password?.message
                              ? (<ErrorOutline data-testid={ `${cmpSelector}PasswordTxtFldErrorIcon` } color='error' />)
                              : (!!(dirtyFields?.password) && <Check data-testid={ `${cmpSelector}PasswordTxtFldValidIcon` } color='primary' />)
                          }}
                        />
                      </Grid>
                      <Grid item xs={ 12 }>
                        <ExtensivButton
                          color='brand'
                          isLoading={ isLoading || isAuthLoading }
                          disabled={ !isValid }
                          onClick={ handleLogin }
                          data-testid={ `${cmpSelector}SubmitBtn` }
                          fullWidth
                        >
                          LOGIN
                        </ExtensivButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={ 12 }>
                    {isShowAuthFailedException && (
                      <Alert
                        icon={ <FontAwesomeIcon icon={ faHexagonExclamation } /> }
                        severity='error'
                        className={ classes.AuthFailedExceptionAlert }
                      >
                        <AlertTitle>Wrong username or password</AlertTitle>
                        Please try again
                      </Alert>
                    )}
                    <Link
                      to={ AUTH_ROUTES.RESET_PASSWORD }
                      className={ classes.ForgotPasswordLink }
                      data-testid={ `${cmpSelector}ForgotPasswordLink` }
                    >
                      Forgot Password?
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!isSmall && displayLoginContent()}
    </Grid>
  )
}
