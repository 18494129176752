import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { showNotification } from '@core/redux/snackbarSlice'
import {
  forgotPassword as forgotPasswordApi,
  confirmPassword as confirmPasswordApi,
} from '@core/services/auth.service'


export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email: string, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await forgotPasswordApi(email)
      return response
    }
    catch (err: any) {
      console.log('err', err)
      dispatch(showNotification({ type: 'error', msg: err?.message }))
      return rejectWithValue(err)
    }
  }
)

export const confirmPassword = createAsyncThunk(
  'auth/confirmPassword',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await confirmPasswordApi(payload.email, payload.verificationCode, payload.newPassword)
      return response
    }
    catch (err: any) {
      console.log('err', err)
      dispatch(showNotification({ type: 'error', msg: err?.message }))
      return rejectWithValue(err)
    }
  }
)

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    isLoading: false,
    isShowChangePasswordDialog: false,
  },
  reducers: {
    clearState: (state) => {
      state.isLoading = false
      return state
    },
  },
  extraReducers: builder => {
    builder
      .addCase(forgotPassword.pending, (state: any) => {
        state.isLoading = true
      })
      .addCase(forgotPassword.fulfilled, (state: any) => {
        state.isLoading = false
      })
      .addCase(forgotPassword.rejected, (state: any) => {
        state.isLoading = false
      })
      .addCase(confirmPassword.pending, (state: any) => {})
      .addCase(confirmPassword.fulfilled, (state: any) => {})
      .addCase(confirmPassword.rejected, (state: any) => {})
  }
})

export const { clearState } = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer
