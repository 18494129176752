import { makeStyles } from 'tss-react/mui'

import CssVariables from '@core/assets/styles/css-variables'

export default makeStyles()((theme) => ({
  DialogContent: {
    padding: '16px 16px 10px 16px'
  },
  DialogActions: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  VersionText: {
    fontWeight: 'normal !important',
    marginLeft: '5px'
  },
  UpdateLink: {
    textDecoration: 'none'
  },
  UpdateLinkText: {
    color: `${CssVariables.primary} !important`
  }
}))
