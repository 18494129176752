import { makeStyles } from 'tss-react/mui'

import CssVariables from '@core/assets/styles/css-variables'

export default makeStyles()((theme) => ({
  AppGridContainer: {
    maxHeight: '400px',
    overflow: 'auto',
    padding: 0,
    '& > a': {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      color: CssVariables.black
    }
  },
  AppMenuItem: {
    padding: '10px 16px',
    width: '100%',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  AppIcon: {
    height: '16px',
    width: 'auto',
    marginRight: '12px'
  },
  LinkContentContainer: {
    justifyContent: 'center',
    alignSelf: 'baseline'
  },
  AppIconContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  AppIconShineOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'skew(225deg)',
    width: '75px',
    height: '75px',
    position: 'absolute',
    left: '37px'
  },
  AppNameContainer: {
    marginTop: '12px',
    textAlign: 'center'
  },
  AppItemLink: {
    textDecoration: 'none !important',
    color: '#333',
    textAlign: 'center',
    width: '100%'
  },
  AppUpdateNotificationIcon: {
    backgroundColor: CssVariables.accent,
    borderRadius: 100,
    height: 10,
    width: 10,
    position: 'absolute',
    right: 10,
    top: 10
  },
  UpdateNotificationPopover: {
    '& .MuiPopover-paper': {
      background: '#000000CC',
      padding: '5px 10px',
      maxWidth: 260,
      marginTop: 5
    }
  }
}))
