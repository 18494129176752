import { useEffect } from 'react'

import { environments } from '@extensiv-app/utility'

import { useAppSelector } from '@core/redux/store'

export default () => {
  const { authData } = useAppSelector((state: any) => state.login)

  useEffect(() => {
    const params = new URLSearchParams({
      jwt: authData.getIdToken().getJwtToken(),
      redirectUri: `${environments.REACT_SKUBANA_LEGACY_IFRAME_URL}/appstore/appview/cartroverwmstest`
    });

    (window as Window).location = `${environments.REACT_SKUBANA_LEGACY_IFRAME_URL}/loginjwt?${params.toString()}`
  }, [])

  // Probably dont need this flow anymore but leaving for now just in case
  // const [ applicableOrgs, setApplicableOrgs ] = useState([])
  // const [ selectedOrg, setSelectedOrg ] = useState('')
  // const [ orgsLoading, setOrgsLoading ] = useState(false)

  // useEffect(() => {
  //   getOrgsList()
  // }, [])

  // const getOrgsList = async () => {
  //   setOrgsLoading(true)
  //
  //   try {
  //     const { status, data } = await apiGetAllOrgs()
  //
  //     if (status !== 200) return
  //
  //     const orgsWithOrderManagerId = data.data.filter((o: IOrganization) => !!o.productsID?.orderManagerCompanyID)
  //
  //     setApplicableOrgs(orgsWithOrderManagerId)
  //     setOrgsLoading(false)
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  // const generateTokenAndRedirect = async () => {
  //   const currentOrgKey = currentOrg.key
  //
  //   try {
  //     const sessionForSelectedOrg = await setSelectedOrgToUserAttribute(selectedOrg) as CognitoUserSession
  //     const jwtForSelectedOrg = sessionForSelectedOrg.getIdToken().getJwtToken()
  //
  //     const redirectUri = `${environments.REACT_SKUBANA_LEGACY_IFRAME_URL}/appstore/appview/cartroverwmstest`
  //
  //     // Set it back to the org we started from
  //     await setSelectedOrgToUserAttribute(currentOrgKey);
  //
  //     (window as Window).location = `${environments.REACT_SKUBANA_LEGACY_IFRAME_URL}/loginjwt?jwt=${jwtForSelectedOrg}&redirectUri=${redirectUri}`
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  // return (
  //   <>
  //     <div className={ classes.BackgroundImage } />
  //     <div className={ classes.BackgroundImageOverlay } />
  //     <Grid container className={ classes.CenteredGrid }>
  //       <Grid item xs={ 12 } sm={ 10 } md={ 8 } lg={ 4 } xl={ 3 }>
  //         <Card className={ classes.PaddedCard }>
  //           <Grid container>
  //             <Grid item xs={ 12 }>
  //               <Text.H2>Please select an organization to link</Text.H2>
  //             </Grid>
  //             <Grid item xs={ 12 }>
  //               <Text small overflowEllipsis={ false }>
  //                 The selected organization's Order Manager instance will be linked
  //                 to Integration Manager
  //               </Text>
  //             </Grid>
  //             { orgsLoading
  //               ?  <Spinner />
  //               : (
  //                 <>
  //                   <Grid item xs={ 12 }>
  //                     <FormControl className={ classes.FormControl }>
  //                       <InputLabel id='org-checkbox-label'>Organization</InputLabel>
  //                       <Select
  //                         labelId='org-checkbox-label'
  //                         value={ selectedOrg }
  //                         onChange={ ({ target: { value } }: SelectChangeEvent<string>) => setSelectedOrg(value) }
  //                         sx={{ width: '100%' }}
  //                         input={ <OutlinedInput label='Organization' /> }
  //                         data-testid='OMLinkOrganizationSelect'
  //                       >
  //                         { applicableOrgs.map(({ key, displayName }) => (
  //                           <MenuItem
  //                             key={ key }
  //                             value={ key }
  //                             className={ classes.OrganizationDropdownMenuOption }
  //                             dense
  //                             disableGutters
  //                             data-testid={ `OMLinkOrganizationSelectOption${displayName}` }
  //                           >
  //                             <ListItemText primary={ displayName } />
  //                           </MenuItem>
  //                         ))}
  //                       </Select>
  //                     </FormControl>
  //                   </Grid>
  //                   <Grid item xs={ 12 } sx={{ marginTop: '20px' }}>
  //                     <ExtensivButton
  //                       variant='contained'
  //                       disabled={ selectedOrg == '' }
  //                       onClick={ generateTokenAndRedirect }
  //                       disableElevation
  //                       data-testid='LinkButton'
  //                       fullWidth
  //                     >
  //                       Link
  //                     </ExtensivButton>
  //                   </Grid>
  //                 </>
  //               )
  //             }
  //           </Grid>
  //         </Card>
  //       </Grid>
  //     </Grid>
  //   </>
  // )
  return null
}
