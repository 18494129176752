import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  MfaLayoutContainer: {
    padding: '0px 24px',
    flexDirection: 'column',
    height: '100vh'
  },
  MfaLayoutHeader: {
    padding: '16px 0px',
    flex: 0
  },
  MfaLayoutContent: {
    flex: 1
  },
  MfaLayoutFooter: {
    textAlign: 'center',
    flex: 0,
    marginBottom: '130px'
  },
  HelpText: {
    color: '#00000099'
  }
}))
