import { CoreLink } from '@extensiv/shared-reactcomponents'
import { Box, Container, Dialog, Typography } from '@mui/material'

import extensivLogoHorizontal from '@core/assets/img/extensiv-logo-200.svg'
import CssClasses from '@core/assets/styles/css-classes'
import { AUTH_ROUTES } from '@core/constants/coreRoutes'

import useStyles from './styles'

export default () => {
  const { classes } = useStyles()
  const { classes: commonClasses } = CssClasses()

  return (
    <Dialog open>
      <Container className={ `${classes.container} ${commonClasses.dFlexCenter}` }>
        <span className={ classes.logo }>
          <img src={ extensivLogoHorizontal } alt='Extensiv' />
        </span>
        <Typography className={ classes.boldText }>Success!</Typography>
        <Typography className={ classes.infoText }>Your Account has been created!</Typography>
        <Box className={ classes.redirectText }>
          <CoreLink color='secondary' to={ AUTH_ROUTES.LOGIN }>
            Go to Login page
          </CoreLink>
        </Box>
      </Container>
    </Dialog>
  )
}
