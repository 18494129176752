import { Route, Redirect } from 'react-router-dom'

import { AUTH_ROUTES, CORE_APP_ROOT, MFA_SETUP } from '@core/constants/coreRoutes'
import AppLayout from '@core/containers/AppLayout'
import MfaLayout from '@core/containers/MfaLayout'
import { useAppSelector } from '@core/redux/store'
import { isAuthRoutes } from '@core/services/auth.service'
import { LocationState } from "@core/types/Location"

const PrivateRoute: any = ({ component, ...rest }: { component: any, restricted: boolean }) => {
  const Component = component
  const { cognitoSession, isUserMfaConfigured } = useAppSelector((state: any) => state.auth)
  const isAuthenticated = cognitoSession && cognitoSession.idToken ? true : false

  const cognitoMfaRequired = cognitoSession?.idToken.payload.mfaRequired
  const needsMfaSetup = !isUserMfaConfigured && cognitoMfaRequired

  return (
    <Route
      { ...rest }
      render={ ({ location }) => {
        if (needsMfaSetup && location.pathname != MFA_SETUP) {
          return <Redirect to={{ pathname: MFA_SETUP }} />
        }

        if (location.pathname == MFA_SETUP) {
          // Don't render MFA screens with regular layout
          return cognitoMfaRequired
            ? <MfaLayout><Component /></MfaLayout>
            : <Redirect to={{ pathname: CORE_APP_ROOT }} />
        }

        if (!isAuthRoutes(location.pathname) && isAuthenticated) {
          const from = (location.state as LocationState)?.from

          if (from) return <Redirect to={{ pathname: from }} />

          return (<AppLayout><Component { ...location } /></AppLayout>)
        }

        return (
          <Redirect
            to={{
              pathname: AUTH_ROUTES.LOGIN,
              state: { from: location.pathname }
            }}
          />
        )} }
    />
  )
}

export default PrivateRoute
