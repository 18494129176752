import { defaultPolygonColors, defaultPolygonShapes } from './styles'
import { TClipPathProp, TClipPathPositions } from './types'

export const getClipPathProp = (prop?: TClipPathProp) => {
  if (!prop || typeof prop === 'boolean') {
    return false
  }

  return prop
}


export const getBackgroundColor = (key: TClipPathPositions, prop?: TClipPathProp) => {
  if (!prop || typeof prop === 'boolean') {
    return defaultPolygonColors[key]
  }

  if (typeof prop === 'string') {
    return prop.startsWith('#') ? prop : defaultPolygonColors[key]
  }

  if (typeof prop === 'object') {
    return prop.color
  }
}

export    const getClipPath = (key: TClipPathPositions, prop?: TClipPathProp) => {
  if (!prop || typeof prop === 'boolean') {
    return defaultPolygonShapes[key]
  }

  if (typeof prop === 'string') {
    return prop.startsWith('polygon') ? prop : defaultPolygonShapes[key]
  }

  if (typeof prop === 'object') {
    return prop.clipPath
  }
}
