import { BaseSyntheticEvent, useState } from 'react'

import { ExtensivButton, CoreTypography } from '@extensiv/shared-reactcomponents'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextField, Grid } from '@mui/material'
import { Link, useHistory } from 'react-router-dom'

import ExtensivLogo from '@core/assets/img/extensiv-logo-200.svg'
import { AUTH_ROUTES } from '@core/constants/coreRoutes'
import { forgotPassword } from '@core/redux/forgotPasswordSlice'
import { useAppDispatch } from '@core/redux/store'

import { useStyles } from './styles'



export default () => {
  const cmpSelector = 'ResetPassword'
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [ email, setEmail ] = useState<string>('')

  const sendCode = async (event: BaseSyntheticEvent) => {
    event.preventDefault()
    if (!email) return

    dispatch(forgotPassword(email))
    history.push(AUTH_ROUTES.CREATE_PASSWORD, { email })
  }

  return (
    <Grid container className={ classes.PageContainer }>
      <Grid item xs={ 12 }>
        <img src={ ExtensivLogo } className={ classes.ExtensivLogo } alt='Extensiv logo' />
      </Grid>
      <Grid item xs={ 12 }>
        <Grid container className={ classes.MainFormBody }>
          <Grid item className={ classes.ResetFormContainer }>
            <Grid container rowSpacing={ 5 }>
              <Grid item xs={ 12 }>
                <Link to={ AUTH_ROUTES.LOGIN } className={ classes.LoginLink }>
                  <CoreTypography variant='bodyLg'>
                    <FontAwesomeIcon icon={ faArrowLeft } style={{ marginRight: '8px' }} />
                    Back to login
                  </CoreTypography>
                </Link>
              </Grid>
              <Grid item xs={ 12 }>
                <Grid container rowSpacing={ 1 }>
                  <Grid item xs={ 12 }>
                    <CoreTypography variant='headingXl'>
                      Reset password
                    </CoreTypography>
                  </Grid>
                  <Grid item xs={ 12 }>
                    <CoreTypography variant='bodyMd'>
                      Enter the email address linked with your account, and we’ll
                      send you a verification code to reset your password.
                    </CoreTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={ 12 }>
                <Grid container rowSpacing={ 2 }>
                  <Grid item xs={ 12 }>
                    <TextField
                      label='Email'
                      variant='outlined'
                      onChange={ ({ target: { value } }) => setEmail(value) }
                      value={ email }
                      required
                      type='email'
                      data-testid={ `${cmpSelector}EmailTxtFld` }
                      size='medium'
                      InputProps={{
                        sx: { height: '56px' },
                      }}
                    />

                  </Grid>
                  <Grid item xs={ 12 }>
                    <ExtensivButton
                      color='brand'
                      disabled={ !email }
                      onClick={ sendCode }
                      fullWidth
                    >
                      SEND CODE
                    </ExtensivButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
