import { makeStyles } from 'tss-react/mui'

import CssVariables from '@core/assets/styles/css-variables'


export default makeStyles()((theme: any) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    transition: theme.transitions.create([ 'width', 'margin' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: CssVariables.headerBackground,
    '& .MuiToolbar-root': {
      borderBottomWidth: 3,
      borderBottomStyle: 'solid',
      borderBottomColor: CssVariables.primary,
      height: CssVariables.headerHeight,
      padding: 0,
      '& .HeaderTab-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        color: CssVariables.white,
        backgroundColor: CssVariables.headerTabsBackground,
        '&.org-selection': {
          width: 304,
          border: '1px solid #424242',
          borderRadius: '4px',
          padding: '12px 0px',
          marginLeft: '8px',
          height: 'auto',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            borderColor: '#fff'
          },
          '& .MuiFormControl-root': {
            margin: 0,
            height: '100%',
            '& .MuiInputBase-root': {
              height: '100%',
              padding: 0,
              fontSize: 12,
              fontWeight: 'bold',
              lineHeight: '17px',
              borderRadius: 0,
              color: '#F0F0F0',
              '& .MuiInputBase-input': {
                padding: 0,
                paddingLeft: 16,
                paddingRight: 30,
                height: '100%',
                fontFamily: 'Inter',
                fontWeight: 700,
                WebkitTextFillColor: '#F0F0F0'
              },
              '& .MuiAutocomplete-endAdornment': {
                top: 'unset',
                '& .MuiAutocomplete-popupIndicator': {
                  color: CssVariables.white,
                  marginRight: 8,
                }
              }
            }
          },
          '&.Mui-focused': {
            color: CssVariables.black,
            backgroundColor: CssVariables.white,
            border: '1px solid',
            '& .MuiInputBase-root': {
              '& .MuiInputBase-input': {
                color: CssVariables.black,
                WebkitTextFillColor: CssVariables.black
              },
              '& .MuiAutocomplete-endAdornment': {
                '& .MuiAutocomplete-popupIndicator': {
                  color: CssVariables.black,
                }
              },
            }
          },
        }
      }
    }
  },
  appBarShift: {
    transition: theme.transitions.create([ 'width', 'margin' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  leftContainerLogo: {
    height: '100%',
    width: CssVariables.drawerWidth,
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
      padding: '5px'
    }
  },
  organizationPaper: {
    maxWidth: 504,
    minWidth: 304,
    width: 'fit-content',
    margin: 0,
    marginTop: '16px',
    borderRadius: 4,
    '& .MuiAutocomplete-listbox': {
      fontSize: 12,
      lineHeight: '18px',
      '& .MuiAutocomplete-option': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
        overflowX: 'hidden',
      }
    }
  },
  superNav: {
    padding: '0 0 0 20px',
    marginLeft: 'auto',
    '& .super-nav-menu': {
      '&.MuiButtonBase-root': {
        marginRight: 0,
        borderRadius: 0,
        padding: '12.5px 12.5px 12.5px 6px',
        paddingRight: 20,
        background: 'transparent',
        color: CssVariables.white,
        '& .MuiSvgIcon-root': {
          fontSize: 30,
          marginRight: 8
        }
      },
      '& .MuiIconButton-root': {
        color: CssVariables.white,
        padding: '17px 6px',
        '& .MuiIconButton-label': {
          '& .MuiSvgIcon-root': {
            fontSize: 30,
            maxHeight: 30,
            maxWidth: 30,
          }
        },
      },
      '&.profile-block': {
        display: 'flex',
        '& .MuiIconButton-root': {
          marginRight: 8,
        },
        '& .profile-details': {
          textAlign: 'left',
          '& .user-name': {
            fontSize: 14,
            fontWeight: 'bold',
          },
          '& .user-role': {
            fontSize: 12,
          }
        },
        '& .profile-avatar-tag': {
          height: 30,
          width: 30,
          marginRight: 8,
          '& img': {
            objectPosition: 'top center',
          }
        }
      },
    }
  },
  appsMenu: {
    overflowY: 'auto',
    maxHeight: '75vh',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: 10,
    padding: 10,
    '& .app-item': {
      cursor: 'pointer',
      textAlign: 'center',
      height: 140,
      width: 140,
      textDecoration: 'none',
      '& svg': {
        height: 110,
        width: 90,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          height: '100%',
          width: '100%'
        }
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: 'Open sans',
        color: CssVariables.labelDark
      },
      '&:hover': {
        boxShadow: '0px 0px 1px #797979',
        transition: 'all 0.3s ease-in-out',
        background: '#efefef',
      }
    }
  },
  menuList: {
    width: 245,
    padding: 0,
    '& .MuiMenuItem-root': {
      height: 40,
      padding: '12px 14px',
      fontSize: 13,
      '& > svg': {
        height: 17,
        width: 17,
        marginRight: 10,
        color: CssVariables.secondary
      },
      '& .Menu-expand-arrow svg': {
        marginRight: 0,
      },
    }
  },
  wideMenuList: {
    border: `1px solid ${CssVariables.paleBlueGrey}`,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    width: 450,
    padding: '0px 4px 4px 4px',
    '& .MuiMenuItem-root': {
      backgroundColor: CssVariables.white,
      marginTop: 4,
      height: 60,
      padding: '12px 14px',
      fontSize: 13,
      '& > svg': {
        height: 25,
        width: 25,
        marginRight: 10,
        color: CssVariables.secondary
      },
      '& .Menu-expand-arrow svg': {
        marginRight: 0,
      },
    }
  },
  submenu: {
    padding: 0,
    '& .MuiMenuItem-root': {
      height: 40,
      padding: '12px 14px',
      fontSize: 13,
      '& svg': {
        height: 17,
        width: 17,
        marginRight: 10,
        color: CssVariables.secondary
      },
      '& .Menu-expand-arrow svg': {
        marginRight: 0,
      },
    }
  },
  extensivIdMenuWrapper: {
    height: 'auto !important',
    cursor: 'pointer',
    '&:hover .extensiv-id': {
      color: '#0288d1',
      '&.text-secondary': {
        color: 'rgba(0, 0, 0, 0.87)',
      }
    },
  },
  extensivIdTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '-4px',
    flex: 1,
  },
  extensivIdTitle: {
    color: 'rgba(0, 0, 0, 0.60)',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '12px',
    letterSpacing: '0.15px',
  },
  extensivId: {
    cursor: 'pointer',
    overflow: 'hidden',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFeatureSettings: "'clig' off, 'liga' off",
    textOverflow: 'ellipsis',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },
}))
