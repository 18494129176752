import { themeObj } from '@extensiv/shared-reactcomponents/Theme'
import { createTheme } from '@mui/material'

import CssVariables from './css-variables'

// Creating a theme instance.
const newConfig: any = {
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 14,
  },
  shape: {},
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: 14
        },
        a: {
          color: CssVariables.primary
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: CssVariables.cardTitleBackground,
          color: CssVariables.white,
          fontWeight: 'bold',
          fontSize: '14px',
          padding: '20px 15px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
        InputLabelProps: {},
        InputProps: {},
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        },
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary'
      }
    },
  },
}

export const theme = createTheme( themeObj, newConfig)
