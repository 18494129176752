import { UserNotifyWebsocketMessage, UserNotifyType } from '@extensiv/sdk/hub'
import { getAppStatus } from "single-spa"

import { IWebsocketMessage } from '@core/extensiv-app-utility'
import { setCurrentAppOutdated, setAppVersionUpdateAvailable } from '@core/redux/appSlice'
import { showNotification } from '@core/redux/snackbarSlice'
import store from '@core/redux/store'

const dispatch = store.dispatch

export type RouteAction = (data: any) => void

interface RouteMap {
  [key: string]: RouteAction
}

const userTopicRoutes: RouteAction = (
  {
    type,
    description = 'An error occurred',
    originalEventId
  }: UserNotifyWebsocketMessage
) => {
  const dispatchError = () => dispatch(showNotification({ msg: `Error: ${description}`, type: 'error' }))

  const userRouteMap: Record<UserNotifyType, () => void> = {
    [UserNotifyType.ACTIVATEORG3W]: dispatchError,
    [UserNotifyType.CREATEORG3W]: dispatchError,
    [UserNotifyType.ACTIVATEUSER3W]: dispatchError,
    [UserNotifyType.CREATEUSER3W]: dispatchError,
    [UserNotifyType.ACTIVATEORGIM]: dispatchError,
    [UserNotifyType.CREATEORGIM]: dispatchError,
    [UserNotifyType.ACTIVATEUSERIM]: dispatchError,
    [UserNotifyType.CREATEUSERIM]: dispatchError,
    [UserNotifyType.ACTIVATEORGOM]: dispatchError,
    [UserNotifyType.CREATEORGOM]: dispatchError,
    [UserNotifyType.ACTIVATEUSEROM]: dispatchError,
    [UserNotifyType.CREATEUSEROM]: dispatchError,
    [UserNotifyType.ACTIVATEORGWM]: dispatchError,
    [UserNotifyType.CREATEORGWM]: dispatchError,
    [UserNotifyType.ACTIVATEUSERWM]: dispatchError,
    [UserNotifyType.CREATEUSERWM]: dispatchError,
  }

  if (type && userRouteMap[type as UserNotifyType]) {
    userRouteMap[type as UserNotifyType]()
  } else {
    console.warn(`Could not handle userNotify message of type ${ type } `)
  }
}

class WebsocketMessageRouter {
  routes: RouteMap = {
    applicationVersionUpdate: ({ app, version }) => {
      if (!app || !version) return

      const appStatus = getAppStatus(app)

      if (appStatus == 'MOUNTED') {
        console.log(`Update available for currently mounted app ${app}. Notifying user for refresh`)
        dispatch(setCurrentAppOutdated({ app, version }))
        return
      }

      console.log(`Update available for ${app}, unmounting to allow for fresh load on next request`)
      dispatch(setAppVersionUpdateAvailable({ app, version }))
    }
  }

  routeMessage = ({ action, data }: IWebsocketMessage) => {
    this.routes[action](data)
  }

  setUserTopicRoute = (routeName: string) => {
    this.routes[routeName] = userTopicRoutes
  }

  removeUserTopicRoute = (routeName: string) => {
    delete this.routes[routeName]
  }
}

export const websocketMessageRouter = new WebsocketMessageRouter()
