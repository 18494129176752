import { MfaSetup, CoreTypography } from '@extensiv/shared-reactcomponents'
import { faInfoSquare } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Alert, AlertTitle, Link } from '@mui/material'

import { CORE_APP_ROOT } from '@core/constants/coreRoutes'
import { useAppSelector } from '@core/redux/store'
import { verifyMfaCode, getTotpSecretCode, refreshSessionWithToken } from '@core/services/auth.service'

import { useStyles } from './styles'

export default () => {
  const { classes } = useStyles()
  const { cognitoSession, isUserMfaConfigured } = useAppSelector((state: any) => state.auth)

  const refreshSession = async (callback: () => void) => {
    await refreshSessionWithToken(cognitoSession.getRefreshToken())

    callback()
  }

  return (
    <Grid container>
      <Grid item xs={ 12 }>
        <Alert
          severity='info'
          icon={ <FontAwesomeIcon icon={ faInfoSquare } /> }
          action={
            <Link
              href=''
              underline='hover'
              className={ classes.AlertLink }
            >
              Learn more about 2FA
            </Link>
          }
        >
          <AlertTitle>
            <CoreTypography variant='bodyLg'>
              Two-factor authentication (2FA) is required by your organization
            </CoreTypography>
          </AlertTitle>
          <CoreTypography variant='bodyMd'>
            Please set it up now to be able to access your account
          </CoreTypography>
        </Alert>
      </Grid>
      <Grid item xs={ 12 } className={ classes.SetupStepsContent }>
        <MfaSetup
          isAlreadyConfigured={ isUserMfaConfigured }
          onDone={ () => (window as Window).location = CORE_APP_ROOT }
          refreshSession={ refreshSession }
          userEmail={ cognitoSession.idToken.payload.email }
          getTotpSecretCode={ getTotpSecretCode }
          verifyCode={ verifyMfaCode }
          isInitialSetup
        />
      </Grid>
    </Grid>
  )
}
