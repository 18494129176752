import { Theme } from '@mui/system'
import { makeStyles } from 'tss-react/mui'

import CssVariables from '../../assets/styles/css-variables'

import { getBackgroundColor, getClipPath } from './func'
import { TClipPathProps, TClipPathPositions } from './types'

export const defaultPolygonColors = {
  top: CssVariables.secondary,
  bottom: CssVariables.white,
  left: CssVariables.darkGray,
  right: CssVariables.primary,
}

export const defaultPolygonShapes = {
  top: 'polygon(0 0, 0% 100%, 100% 100%)',
  bottom: 'polygon(0 0, 0% 100%, 100% 0)',
  left: 'polygon(100% 0, 0% 100%, 100% 100%)',
  right: 'polygon(0 0, 100% 0%, 100% 100%)'
}

export const useStyles = makeStyles<TClipPathProps>()((theme: Theme, { top, bottom, left, right }: TClipPathProps) => ({
  polygon: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  topPolygon: {
    top: '-100%',
    left: 0,
    clipPath: getClipPath(TClipPathPositions.top, top),
    backgroundColor: getBackgroundColor(TClipPathPositions.top, top)
  },
  bottomPolygon: {
    left: 0,
    bottom: '-100%',
    clipPath: getClipPath(TClipPathPositions.bottom, bottom),
    backgroundColor: getBackgroundColor(TClipPathPositions.bottom, bottom)
  },
  leftPolygon: {
    top: 0,
    left: '-100%',
    clipPath: getClipPath(TClipPathPositions.left, left),
    backgroundColor: getBackgroundColor(TClipPathPositions.left, left)
  },
  rightPolygon: {
    top: 0,
    right: '-100%',
    clipPath: getClipPath(TClipPathPositions.right, right),
    backgroundColor: getBackgroundColor(TClipPathPositions.right, right)
  }
}))
