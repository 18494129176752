import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { showNotification } from '@core/redux/snackbarSlice'
import { confirmRegistration } from '@core/services/auth.service'

// TODO: this flow is not used any more with cognito but needs to verify and then remove whole code
export const verifyRegistration = createAsyncThunk(
  'auth/verifyRegistration',
  async ({ email, code }: any, { dispatch, rejectWithValue }) => {
    try {
      const response: any = await confirmRegistration(email, code)
      dispatch(showNotification({
        type: 'success',
        msg: 'Your account has been verified successfully!'
      }))
      return response
    }
    catch (err: any) {
      console.log('err', err)
      dispatch(showNotification({ type: 'error', msg: err?.message }))
      return rejectWithValue(err)
    }
  }
)


export const verifyEmailSlice = createSlice({
  name: 'verifyEmail',
  initialState: {
    isLoading: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(verifyRegistration.pending, (state) => {
        state.isLoading = true
      })
      .addCase(verifyRegistration.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(verifyRegistration.rejected, (state) => {
        state.isLoading = false
      })
  },
})

// export const { } = verifyEmailSlice.actions
export default verifyEmailSlice.reducer
