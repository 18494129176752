import { IExtensivSnackbarProps } from '@extensiv/shared-reactcomponents'
import { createSlice } from '@reduxjs/toolkit'

export interface SnackbarState {
  msg: string | null
  type: string | null
  option: Partial<IExtensivSnackbarProps> | null
}

interface SnackbarMessageAction {
  payload: {
    type: string
    msg: string
    option?: Partial<IExtensivSnackbarProps> | null
  }
}

const initialState: SnackbarState = {
  msg: null,
  type: null,
  option: null
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showNotification: (state, { payload }: SnackbarMessageAction) => {
      state.msg = payload.msg
      state.type = payload.type
      state.option = payload?.option ? payload.option : null
      return state
    },
    clearStateSnackbar: (state) => {
      state.msg = null
      state.type = null
      state.option = null
      return state
    },
  },
})

export const { showNotification, clearStateSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer
